:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._fd-column{flex-direction:column;}
:root ._fg-1{flex-grow:1;}
:root ._ai-center{align-items:center;}
:root ._ai-stretch{align-items:stretch;}
:root ._fs-0{flex-shrink:0;}
:root ._zi-40{z-index:40;}
:root ._mt-t-space-10{margin-top:var(--t-space-10);}
:root ._h-t-size-10{height:var(--t-size-10);}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._pt-t-space-10{padding-top:var(--t-space-10);}
:root ._pr-t-space-4--53{padding-right:var(--t-space-4--5);}
:root ._pl-t-space-4--53{padding-left:var(--t-space-4--5);}
:root ._maw-600px{max-width:600px;}
:root ._w-10037{width:100%;}
:root ._btlr-t-radius-8{border-top-left-radius:var(--t-radius-8);}
:root ._btrr-t-radius-8{border-top-right-radius:var(--t-radius-8);}
:root ._bbrr-t-radius-8{border-bottom-right-radius:var(--t-radius-8);}
:root ._bblr-t-radius-8{border-bottom-left-radius:var(--t-radius-8);}
:root ._bxsh-0px5px6pxva26674076{box-shadow:0px 5px 6px var(--shadowColor);}
:root ._gap-t-space-3{gap:var(--t-space-3);}
:root ._jc-center{justify-content:center;}
:root ._bg-background{background-color:var(--background);}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btc-strokeSecon3075972{border-top-color:var(--strokeSecondary);}
:root ._brc-strokeSecon3075972{border-right-color:var(--strokeSecondary);}
:root ._bbc-strokeSecon3075972{border-bottom-color:var(--strokeSecondary);}
:root ._blc-strokeSecon3075972{border-left-color:var(--strokeSecondary);}
:root ._pt-t-space-9{padding-top:var(--t-space-9);}
:root ._pr-t-space-9{padding-right:var(--t-space-9);}
:root ._pb-t-space-9{padding-bottom:var(--t-space-9);}
:root ._pl-t-space-9{padding-left:var(--t-space-9);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
@media (max-width: 800px){:root:root:root:root ._pt-_sm_t-space-4{padding-top:var(--t-space-4);}}
@media (max-width: 800px){:root:root:root:root ._pr-_sm_t-space-4{padding-right:var(--t-space-4);}}
@media (max-width: 800px){:root:root:root:root ._pb-_sm_t-space-4{padding-bottom:var(--t-space-4);}}
@media (max-width: 800px){:root:root:root:root ._pl-_sm_t-space-4{padding-left:var(--t-space-4);}}