:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-9{font-weight:var(--f-weight-9);}
:root ._fos-32px{font-size:32px;}
:root ._lh-35--2px{line-height:35.2px;}
:root ._col-color{color:var(--color);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._whiteSpace-normal{white-space:normal;}
:root ._fow-f-weight-4{font-weight:var(--f-weight-4);}
:root ._fos-f-size-4{font-size:var(--f-size-4);}
:root ._lh-f-lineHeigh112923{line-height:var(--f-lineHeight-4);}
:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._pt-t-space-5{padding-top:var(--t-space-5);}
:root ._pb-t-space-5{padding-bottom:var(--t-space-5);}
:root ._fs-1{flex-shrink:1;}
:root ._fd-column{flex-direction:column;}
:root ._fg-1{flex-grow:1;}
:root ._ta-center{text-align:center;}
:root ._fow-f-weight-3{font-weight:var(--f-weight-3);}
:root ._fos-f-size-3{font-size:var(--f-size-3);}
:root ._lh-f-lineHeigh112922{line-height:var(--f-lineHeight-3);}
:root ._col-red10{color:var(--red10);}